<template>

  <div>
        <div>
            <template>
                <v-toolbar
                    flat
                >
                <v-toolbar-title>{{ $t('Sales Plan Measures') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    :to="{ name: 'SalesPlanMeasuresCreate' }"
                    fab
                    dark
                    color="primary"
                >
                    <v-icon
                    dark
                    >
                    mdi-plus
                    </v-icon>
                </v-btn>
                </v-toolbar>
            </template>
        </div>
        <div class="mt-4">

            <div>
                <v-expansion-panels accordion>
                    <v-expansion-panel >
                        <v-expansion-panel-header>{{$t("Company Level")}}</v-expansion-panel-header>
                        <v-expansion-panel-content class="">
                            <v-data-table
                            :headers="headers"
                            :items="items"
                            :search="search"
                            :options.sync="options"
                            :server-items-length="totalItems"
                            :loading="loading"
                            sort-by="sort"
                            :sort-desc.sync="sortDesc"
                            must-sort
                            class=""
                            calculate-widths
                            :hide-default-footer="true"
                          >
                            <template v-slot:top>
                              <v-toolbar
                                flat
                              >
                                <v-spacer></v-spacer>
                                <v-text-field
                                  v-model="options.search"
                                  append-icon="mdi-magnify"
                                  :label="$t('Search')"
                                  single-line
                                  hide-details
                                  clearable
                                  class="shrink mr-5"
                                ></v-text-field>
                              </v-toolbar>
                            </template>

                            <template v-slot:item.sort="{item}" >
                                <v-btn @click="sortUp(item)" plain text icon class="mr-2">
                                    <v-hover v-slot="{ hover }">
                                    <v-icon :color="hover? 'primary': 'grey'"
                                    >
                                    mdi-chevron-up
                                    </v-icon>
                                  </v-hover>
                                </v-btn>

                                <v-btn @click="sortDown(item)" plain text icon class="mr-2">
                                    <v-hover v-slot="{ hover }">
                                      <v-icon :color="hover? 'primary': 'grey'"
                                      >
                                      mdi-chevron-down
                                      </v-icon>
                                    </v-hover>
                                </v-btn>
                            </template>

                            <template v-slot:item.actions="{item}" >
                                <v-btn :to="{name: 'SalesPlanMeasuresEdit', query: { id: item.id }}" plain text icon class="mr-2">
                                    <v-hover v-slot="{ hover }">
                                    <v-icon :color="hover? 'green': 'grey'"
                                    >
                                    mdi-pencil
                                    </v-icon>
                                  </v-hover>
                                </v-btn>

                                <v-btn :to="{name: 'SalesPlanMeasuresDelete', query: { id: item.id,name:item.name }}" plain text icon class="">
                                    <v-hover v-slot="{ hover }">
                                      <v-icon  :color="hover? 'primary': 'grey'"
                                      >
                                      mdi-delete
                                      </v-icon>
                                    </v-hover>
                                </v-btn>
                            </template>
                            <template v-slot:no-data>
                              {{ $t('No items found') }}
                            </template>
                          </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel >
                        <v-expansion-panel-header>{{$t("Tactical")}}</v-expansion-panel-header>
                        <v-expansion-panel-content class="">
                            <v-data-table
                            :headers="Theaders"
                            :items="Titems"
                            :search="Tsearch"
                            :options.sync="Toptions"
                            :server-items-length="TtotalItems"
                            :loading="Tloading"
                            sort-by="sort"
                            :sort-desc.sync="TsortDesc"
                            must-sort
                            class=""
                            calculate-widths
                            :hide-default-footer="true"
                          >
                            <template v-slot:top>
                              <v-toolbar
                                flat
                              >
                                <v-spacer></v-spacer>
                                <v-text-field
                                  v-model="Toptions.search"
                                  append-icon="mdi-magnify"
                                  :label="$t('Search')"
                                  single-line
                                  hide-details
                                  clearable
                                  class="shrink mr-5"
                                ></v-text-field>
                              </v-toolbar>
                            </template>

                            <template v-slot:item.sort="{item}" >
                                <v-btn @click="sortUp(item)" plain text icon class="mr-2">
                                    <v-hover v-slot="{ hover }">
                                    <v-icon :color="hover? 'primary': 'grey'"
                                    >
                                    mdi-chevron-up
                                    </v-icon>
                                  </v-hover>
                                </v-btn>

                                <v-btn @click="sortDown(item)" plain text icon class="mr-2">
                                    <v-hover v-slot="{ hover }">
                                      <v-icon :color="hover? 'primary': 'grey'"
                                      >
                                      mdi-chevron-down
                                      </v-icon>
                                    </v-hover>
                                </v-btn>
                            </template>
                            <template v-slot:item.actions="{item}" >
                                <v-btn :to="{name: 'SalesPlanMeasuresEdit', query: { id: item.id }}" plain text icon class="mr-2">
                                    <v-hover v-slot="{ hover }">
                                    <v-icon :color="hover? 'green': 'grey'"
                                    >
                                    mdi-pencil
                                    </v-icon>
                                  </v-hover>
                                </v-btn>

                                <v-btn :to="{name: 'SalesPlanMeasuresDelete', query: { id: item.id,name:item.name }}" plain text icon class="">
                                    <v-hover v-slot="{ hover }">
                                      <v-icon  :color="hover? 'primary': 'grey'"
                                      >
                                      mdi-delete
                                      </v-icon>
                                    </v-hover>
                                </v-btn>
                            </template>
                            <template v-slot:no-data>
                              {{ $t('No items found') }}
                            </template>
                          </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel >
                        <v-expansion-panel-header>{{$t("Activity")}}</v-expansion-panel-header>
                        <v-expansion-panel-content class="">
                            <v-data-table
                            :headers="Aheaders"
                            :items="Aitems"
                            :search="Asearch"
                            :options.sync="Aoptions"
                            :server-items-length="AtotalItems"
                            :loading="Aloading"
                            sort-by="sort"
                            :sort-desc.sync="AsortDesc"
                            must-sort
                            class=""
                            calculate-widths
                            :hide-default-footer="true"
                          >
                            <template v-slot:top>
                              <v-toolbar
                                flat
                              >
                                <v-spacer></v-spacer>
                                <v-text-field
                                  v-model="Aoptions.search"
                                  append-icon="mdi-magnify"
                                  :label="$t('Search')"
                                  single-line
                                  hide-details
                                  clearable
                                  class="shrink mr-5"
                                ></v-text-field>
                              </v-toolbar>
                            </template>

                            <template v-slot:item.sort="{item}" >
                                <v-btn @click="sortUp(item)" plain text icon class="mr-2">
                                    <v-hover v-slot="{ hover }">
                                      <v-icon :color="hover? 'primary': 'grey'"
                                      >
                                      mdi-chevron-up
                                      </v-icon>
                                    </v-hover>
                                </v-btn>

                                <v-btn @click="sortDown(item)" plain text icon class="mr-2">
                                    <v-hover v-slot="{ hover }">
                                      <v-icon :color="hover? 'primary': 'grey'"
                                      >
                                      mdi-chevron-down
                                      </v-icon>
                                    </v-hover>
                                </v-btn>
                            </template>

                            <template v-slot:item.actions="{item}" >
                                <v-btn :to="{name: 'SalesPlanMeasuresEdit', query: { id: item.id }}" plain text icon class="mr-2">
                                    <v-hover v-slot="{ hover }">
                                      <v-icon :color="hover? 'green': 'grey'"
                                      >
                                      mdi-pencil
                                      </v-icon>
                                  </v-hover>
                                </v-btn>

                                <v-btn :to="{name: 'SalesPlanMeasuresDelete', query: { id: item.id,name:item.name }}" plain text icon class="">
                                    <v-hover v-slot="{ hover }">
                                      <v-icon  :color="hover? 'primary': 'grey'"
                                      >
                                      mdi-delete
                                      </v-icon>
                                    </v-hover>
                                </v-btn>
                            </template>
                            <template v-slot:no-data>
                              {{ $t('No items found') }}
                            </template>
                          </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                search: '',
                sortDesc:true,
                listSrch: {},
                totalItems: 0,
                options: {},
                loading: true,
                headers: [],
                items: [],

                Tsearch: '',
                TsortDesc:true,
                TlistSrch: {},
                TtotalItems: 0,
                Toptions: {},
                Tloading: true,
                Theaders: [],
                Titems: [],

                Asearch: '',
                AsortDesc:true,
                AlistSrch: {},
                AtotalItems: 0,
                Aoptions: {},
                Aloading: true,
                Aheaders: [],
                Aitems: []
            }
        },

        watch: {
            options: {
                handler () {
                  this.fetchItems()
                },
                deep: true,
            },
            Toptions: {
                handler () {
                  this.fetchTItems()
                },
                deep: true,
            },
            Aoptions: {
                handler () {
                  this.fetchAItems()
                },
                deep: true,
            },
        },
        mounted ()
        {
            this.fetchItems();
            this.fetchTItems();
            this.fetchAItems();
        },
        created: function()
        {

            this.headers.push(
              { text: this.$t('Sort'), value: 'sort', sortable: false },
              { text: this.$t('Name'), value: 'name', sortable: false },
              { text: this.$t('Actions'), align: 'right', value: 'actions', sortable: false }
            )

            this.Theaders.push(
              { text: this.$t('Sort'), value: 'sort', sortable: false },
              { text: this.$t('Name'), value: 'name', sortable: false },
              { text: this.$t('Actions'), align: 'right', value: 'actions', sortable: false }
            )

            this.Aheaders.push(
              { text: this.$t('Sort'), value: 'sort', sortable: false },
              { text: this.$t('Name'), value: 'name', sortable: false },
              { text: this.$t('Actions'), align: 'right', value: 'actions', sortable: false }
            )

        },

        methods: {

            fetchItems()
            {
              if(this.options.page != undefined){
                let uri = this.$urlPrefix + '/SalesPlanMeasure/CountAll?type=0';
                let uri2 = this.$urlPrefix + '/SalesPlanMeasure/GetList?type=0';

                uri2 += '&sort=sort';

                if(this.options.search){
                  uri2 += '&search='+this.options.search;
                }

                this.axios.get(uri).then((response) => {
                  this.totalItems = response.data;
                  this.axios.get(uri2).then((response) => {
                      this.items = response.data;
                      this.loading = false;
                  });
                });
              }
            },

            fetchTItems()
            {
              if(this.Toptions.page != undefined){

                let uri = this.$urlPrefix + '/SalesPlanMeasure/CountAll?type=1';
                let uri2 = this.$urlPrefix + '/SalesPlanMeasure/GetList?type=1';

                uri2 += '&sort=sort';

                if(this.Toptions.search){
                  uri2 += '&search='+this.Toptions.search;
                }

                this.axios.get(uri).then((response) => {
                  this.TtotalItems = response.data;
                  this.axios.get(uri2).then((response) => {
                      this.Titems = response.data;
                      this.Tloading = false;
                  });
                });
              }
            },

            fetchAItems()
            {
              if(this.Aoptions.page != undefined){

                let uri = this.$urlPrefix + '/SalesPlanMeasure/CountAll?type=2';
                let uri2 = this.$urlPrefix + '/SalesPlanMeasure/GetList?type=2';

                uri2 += '&sort=sort';

                if(this.Aoptions.search){
                  uri2 += '&search='+this.Aoptions.search;
                }

                this.axios.get(uri).then((response) => {
                  this.AtotalItems = response.data;
                  this.axios.get(uri2).then((response) => {
                      this.Aitems = response.data;
                      this.Aloading = false;
                  });
                });
              }
            },

            sortUp(item){
                item.sort--;
                this.updateSort(item)
            },
            sortDown(item){
                item.sort++;
                this.updateSort(item)
            },
            saleMeasureUpdate(item){
              let uri = this.$urlPrefix + '/SalesPlanMeasure/Update/';
              this.loading = true;
              this.axios.put(uri, item).then(() => {
                  this.fetchItems();
                  this.fetchTItems();
                  this.fetchAItems();
              });
            },
            updateSort(item){
              let uri = this.$urlPrefix + '/SalesPlanMeasure/UpdateSort';
              this.loading = true;
              this.axios.put(uri, item).then(() => {
                  this.fetchItems();
                  this.fetchTItems();
                  this.fetchAItems();
              });
            }
        }
    }
</script>
<style lang="scss">
  .top-filters{
    width: 150px;
  }
</style>
