var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('Sales Plan Measures')))]),_c('v-spacer'),_c('v-btn',{attrs:{"to":{ name: 'SalesPlanMeasuresCreate' },"fab":"","dark":"","color":"primary"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1)]],2),_c('div',{staticClass:"mt-4"},[_c('div',[_c('v-expansion-panels',{attrs:{"accordion":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.$t("Company Level")))]),_c('v-expansion-panel-content',{},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"sort-by":"sort","sort-desc":_vm.sortDesc,"must-sort":"","calculate-widths":"","hide-default-footer":true},on:{"update:options":function($event){_vm.options=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-text-field',{staticClass:"shrink mr-5",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1)]},proxy:true},{key:"item.sort",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"plain":"","text":"","icon":""},on:{"click":function($event){return _vm.sortUp(item)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'primary': 'grey'}},[_vm._v(" mdi-chevron-up ")])]}}],null,true)})],1),_c('v-btn',{staticClass:"mr-2",attrs:{"plain":"","text":"","icon":""},on:{"click":function($event){return _vm.sortDown(item)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'primary': 'grey'}},[_vm._v(" mdi-chevron-down ")])]}}],null,true)})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"to":{name: 'SalesPlanMeasuresEdit', query: { id: item.id }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'green': 'grey'}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1),_c('v-btn',{attrs:{"to":{name: 'SalesPlanMeasuresDelete', query: { id: item.id,name:item.name }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'primary': 'grey'}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('No items found'))+" ")]},proxy:true}])})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.$t("Tactical")))]),_c('v-expansion-panel-content',{},[_c('v-data-table',{attrs:{"headers":_vm.Theaders,"items":_vm.Titems,"search":_vm.Tsearch,"options":_vm.Toptions,"server-items-length":_vm.TtotalItems,"loading":_vm.Tloading,"sort-by":"sort","sort-desc":_vm.TsortDesc,"must-sort":"","calculate-widths":"","hide-default-footer":true},on:{"update:options":function($event){_vm.Toptions=$event},"update:sortDesc":function($event){_vm.TsortDesc=$event},"update:sort-desc":function($event){_vm.TsortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-text-field',{staticClass:"shrink mr-5",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.Toptions.search),callback:function ($$v) {_vm.$set(_vm.Toptions, "search", $$v)},expression:"Toptions.search"}})],1)]},proxy:true},{key:"item.sort",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"plain":"","text":"","icon":""},on:{"click":function($event){return _vm.sortUp(item)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'primary': 'grey'}},[_vm._v(" mdi-chevron-up ")])]}}],null,true)})],1),_c('v-btn',{staticClass:"mr-2",attrs:{"plain":"","text":"","icon":""},on:{"click":function($event){return _vm.sortDown(item)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'primary': 'grey'}},[_vm._v(" mdi-chevron-down ")])]}}],null,true)})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"to":{name: 'SalesPlanMeasuresEdit', query: { id: item.id }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'green': 'grey'}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1),_c('v-btn',{attrs:{"to":{name: 'SalesPlanMeasuresDelete', query: { id: item.id,name:item.name }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'primary': 'grey'}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('No items found'))+" ")]},proxy:true}])})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.$t("Activity")))]),_c('v-expansion-panel-content',{},[_c('v-data-table',{attrs:{"headers":_vm.Aheaders,"items":_vm.Aitems,"search":_vm.Asearch,"options":_vm.Aoptions,"server-items-length":_vm.AtotalItems,"loading":_vm.Aloading,"sort-by":"sort","sort-desc":_vm.AsortDesc,"must-sort":"","calculate-widths":"","hide-default-footer":true},on:{"update:options":function($event){_vm.Aoptions=$event},"update:sortDesc":function($event){_vm.AsortDesc=$event},"update:sort-desc":function($event){_vm.AsortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-text-field',{staticClass:"shrink mr-5",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.Aoptions.search),callback:function ($$v) {_vm.$set(_vm.Aoptions, "search", $$v)},expression:"Aoptions.search"}})],1)]},proxy:true},{key:"item.sort",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"plain":"","text":"","icon":""},on:{"click":function($event){return _vm.sortUp(item)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'primary': 'grey'}},[_vm._v(" mdi-chevron-up ")])]}}],null,true)})],1),_c('v-btn',{staticClass:"mr-2",attrs:{"plain":"","text":"","icon":""},on:{"click":function($event){return _vm.sortDown(item)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'primary': 'grey'}},[_vm._v(" mdi-chevron-down ")])]}}],null,true)})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"to":{name: 'SalesPlanMeasuresEdit', query: { id: item.id }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'green': 'grey'}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1),_c('v-btn',{attrs:{"to":{name: 'SalesPlanMeasuresDelete', query: { id: item.id,name:item.name }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'primary': 'grey'}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('No items found'))+" ")]},proxy:true}])})],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }